import $ from 'jquery';
/* global ajax_object */
$(document).ready(function () {
  UrlCheck();
  FilteredGrid();
  setActiveButton();
});
function FilteredGrid() {
  $('.js-filtered-grid').each(function () {
    $(document).on('click', '.filtered-grid__filter button', function () {
      event.preventDefault();
      let category = this.id;
      let postType = $(this).data('type');
      $.ajax({
        url: ajax_object.ajax_url,
        type: 'post',
        data: {
          action: 'filter_resources',
          category: category,
          postType: postType,
        },
        success: function (response) {
          $('#filtered-grid .filtered-grid__items').html(response);
          SetURLParameter(category);
        },
      });
    });
  });
}
function UrlCheck() {
  let category = GetURLParameter('resource');
  let postType = 'resources';
  let activeButton = $(
    '#filtered-grid .filtered-grid__filter button#' + category
  );
  activeButton.addClass('active');
  if (category) {
    if (category == 'post') {
      postType = 'post';
      category = undefined;
    }
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'filter_resources',
        category: category,
        postType: postType,
      },
      success: function (response) {
        $('#filtered-grid .filtered-grid__items').html(response);
      },
    });
  }
  function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
      var sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  }
}
function SetURLParameter(resource) {
  if (resource == '') {
    resource = 'post';
  }
  window.history.replaceState(null, null, '?resource=' + resource);
}
function setActiveButton() {
  $('#filtered-grid .filtered-grid__filter button').on('click', function () {
    // Remove 'active' class from all buttons
    $('#filtered-grid .filtered-grid__filter button').removeClass('active');

    // Add 'active' class to the clicked button
    $(this).addClass('active');
  });
}
