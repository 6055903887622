import $ from 'jquery';
$(document).ready(function () {
  advantageTabs();
});
function advantageTabs() {
  $('.advantage-tabs__tab').click(function () {
    let tab_id = $(this).attr('data-tab');

    $('.advantage-tabs__tab').removeClass('active-tab');
    $('.advantage-tabs__image').hide();

    $(this).addClass('active-tab');
    $('#' + tab_id).fadeIn();
  });

  // Activate the first tab by default
  $('.advantage-tabs__tab').first().addClass('active-tab');
  $('.advantage-tabs__image').first().fadeIn(100);
}
