import $ from 'jquery';
$(document).ready(function () {
  imageTabs();
});
function imageTabs() {
  $('.js-image-tabs').each(function () {
    const $tabs = $('.image-tabs__tab');
    const $circle = $('.circle');
    const $images = $('.image-tabs__image');
    const $tabList = $('.image-tabs__list');
    let activeTab = $tabs.first();

    function moveToTab($tab) {
      activeTab = $tab;

      // Get the offset relative to the .image-tabs__list container
      const listOffset = $tabList.offset().top;
      const tabOffset =
        $tab.offset().top -
        listOffset +
        $tab.height() / 2 -
        $circle.height() / 2;

      // Determine if the layout is vertical or horizontal
      const isVertical = $(window).width() <= 769;

      if (isVertical) {
        $circle.css({
          transform: `translateY(${tabOffset}px)`,
        });
      } else {
        $circle.css('transform', `translateY(${tabOffset}px)`);
      }

      const target = $tab.data('tab');
      $images.hide();
      $(`#${target}`).show();
    }

    function adjustCirclePosition() {
      moveToTab(activeTab);
    }
    activeTab.find('.animate-text-bg').addClass('animate-text-active');
    // Initial active state
    moveToTab($tabs.first());

    // Event handling for tabs
    $tabs.hover(function () {
      const $tab = $(this);
      moveToTab($tab);
      $('.image-tabs__tab')
        .find('.animate-text-bg')
        .removeClass('animate-text-active');
      $tab.find('.animate-text-bg').addClass('animate-text-active');
    });

    // Adjust circle position on window resize
    $(window).on('resize', adjustCirclePosition);
  });
}
