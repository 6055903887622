import $ from 'jquery';
$(document).ready(function () {
  columnTabs();
});
function columnTabs() {
  $('.js-column-tabs').each(function () {
    $('.column-tabs__tab').click(function () {
      let tab_id = $(this).attr('data-tab');

      $('.column-tabs__tab').removeClass('active-tab');
      $('.column-tabs__list-content-item').hide();

      $(this).addClass('active-tab');
      $('#' + tab_id).fadeIn();
    });

    $('.column-tabs__tab').first().addClass('active-tab');
    $('.column-tabs__list-content-item').first().fadeIn(100);
  });
}
