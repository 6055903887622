import $ from 'jquery';
$(document).ready(function () {
  eventSliderWidget();
});
function eventSliderWidget() {
  $('.js-event-slider').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    arrows: true,
    adaptiveHeight: true,
    dots: false,
    appendArrows: $('.event-slider__arrow-wrap'),
    prevArrow: $('.event-slider__prev'),
    nextArrow: $('.event-slider__next'),
  });
}
