import $ from 'jquery';

$(document).ready(function () {
  heroSlider();
});

function heroSlider() {
  $('.hero-slider').slick({
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    cssEase: 'ease-in-out',
    appendArrows: $('.arrows-wrap'),
    prevArrow: $('.arrows-wrap__prev'),
    nextArrow: $('.arrows-wrap__next'),
  });
}
