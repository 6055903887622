import $ from 'jquery';
/* global ajax_object */
$(document).ready(function () {
  jobFilter();
});
function jobFilter() {
  $('#job-filter select, #job-title').on('change keyup', function () {
    let category = $('#job-category').val();
    let jobType = $('#job-type').val();
    let jobLocation = $('#job-location').val();
    let jobTitle = $('#job-title').val();

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'post',
      data: {
        action: 'filter_jobs',
        category: category,
        jobType: jobType,
        jobLocation: jobLocation,
        jobTitle: jobTitle,
      },
      success: function (response) {
        $('#job-listings').html(response);
      },
    });
  });
}
